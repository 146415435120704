<script setup lang="ts">
// import TheWelcome from '../components/TheWelcome.vue'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import Footer from '@/components/Footer.vue'

// import VueSweetalert2 from 'vue-sweetalert2'
// import image from '@/assets/images/bg1.jpg'

const router = useRouter()
const route = useRoute()

const isQrCode = ref(false)
// const route = useRoute()
onMounted(() => {
  setTimeout(() => {
    pushWithQuery()
  }, 1000)
})

function pushWithQuery() {
  isQrCode.value = false
  if (route.params.id !== undefined) {
    isQrCode.value = true
    setTimeout(() => {
      router.push({ path: `/qrcode/${route.params.id}` })
    }, 2000)
    // let timerInterval: any
    // Swal.fire({
    //   title: 'Cargando !',
    //   html: 'Espere..!',
    //   timer: 2000,
    //   timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading()
    //     // const timer = Swal.getPopup().querySelector('b')
    //     // timerInterval = setInterval(() => {
    //     //   timer.textContent = `${Swal.getTimerLeft()}`
    //     // }, 100)
    //   },
    //   willClose: () => {
    //     clearInterval(timerInterval)
    //   }
    // }).then((result: any) => {
    //   /* Read more about handling dismissals below */
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     router.push({ path: `/qrcode/${route.params.id}` })
    //   }
    // })
    //
  }
}
// console.log(route.params.id)
// object with path
</script>

<template>
  <div class="container">
    <div class="container1">
      <div class="container-center">
        <div class="container">
          <div class="row">
            <div class="col-15 text-center">
              <div class="card-img">
                <img src="@/assets/images/radiologox-link.png" style="width: 25rem;">

                <div v-if="!isQrCode">
                  <div class="row">
                    <div class="col-24">
                      <h6 class="fw-normal  text-end me-5" style="margin-top:-20px;">
                        Resultados En Linea.
                      </h6>
                    </div>

                    <div class="col-4">
                      <h4> &nbsp;</h4>
                    </div>
                    <div class="col-8">
                      <div class="container w-1">
                        <form class="form-login">
                          <!-- 'sr-only' will hide the text : 'Email Address'. So, 'Email Address' will be invisible -->
                          <!-- <label for="inputEmail" class="sr-only">Email Address</label> -->
                          <!-- 'autofocus' will highlight the input column initially -->
                          <input
                            id="inputEmail"
                            type="text"
                            class="form-control mb-2"
                            placeholder="Usuario"
                            required
                            autofocus
                          >
                          <!-- 'sr-only' will hide the text : 'Password'. So, 'Password' will be invisible -->
                          <!-- <label for="inputPassword" class="sr-only">Password</label> -->
                          <input
                            id="inputPassword"
                            type="password"
                            class="form-control mb-2"
                            placeholder="Contraseña"
                            required
                          >
                          <!-- <div class="checkbox mb-3">
                      <label>
                        <input type="checkbox" value="remember-me"> Remember me
                      </label>
                    </div> -->
                          <!-- 'btn-block' will make the button wider -->
                          <button class="btn btn-sm btn-primary btn-block" type="submit">
                            Acceder
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h4 class="fw-normal mt-4 text-danger text-end me-5" style="margin-top:-20px;">
                    Redirección a Codigo QR
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-24 text-end">
            <!-- <h4 class="text-center fw-normal">
              Resultados En Linea.
            </h4> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<style scoped>
.logo{

  width: 21rem;

}
.container1{

  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

  background-image: url('@/assets/images/bg1.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.container-center {
  background-color: aliceblue;
  position: fixed;
  inset: 0px;
  width: 600px;
  height: 300px;
  max-width: 100vw;
  max-height: 100dvh;
  margin: auto;

  border-radius: 1px 1px;
  border-top-left-radius: 10%;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 30%;
  border-bottom-left-radius: 75%;
}
</style>
