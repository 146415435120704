import './assets/main.css'
import Vue, { createApp } from 'vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { createPinia } from 'pinia'

import axiosInstance from './apis/ajax'
import router from './router'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App.vue'

// import Axios from './apis/ajax'

// import mountElementUI from './utils/elementUI'

document.title = import.meta.env.VITE_APP_TITLE

const app = createApp(App)
app.use(createPinia())

// app.provide('$http', Axios)
app.config.globalProperties.$axios = axiosInstance
app.use(router)

// 全量引入Element UI
// mountElementUI(app)
app.mount('#app')
