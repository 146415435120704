<script setup lang="ts">
// import TheWelcome from '../components/TheWelcome.vue'
import { useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import onlineResult from '../apis/modules/onlineResult'

import Footer from '@/components/Footer.vue'

const route = useRoute()
const idQrcode = ref('')
const idResponseQuery = ref('')
const msgError = ref('')
const hasResults = ref(false)
const dataResponse = ref<any[]>([])
const idImage = ref('')
const timerInterval = ref()
const codePin = ref('')
const blockPage = ref(false)

onMounted(() => {
  idQrcode.value = route.params.id[0]
  hasResults.value = false

  setTimeout(() => {
    getDataFromServer()
  }, 1000)

  Swal.fire({
    title: 'Buscando Resultados !',
    html: 'Espere..!',
    timer: 4000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()

      // const timer = Swal.getPopup().querySelector('b')
      // timerInterval.value = setInterval(() => {
      //   timer.textContent = `${Swal.getTimerLeft()}`
      // }, 100)
    },
    willClose: () => {
      clearInterval(timerInterval.value)
    }
  }).then((result: any) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {

    }
  })

  setTimeout(() => {

  }, 200)
  // console.log(route.params.id)
})

function getDataFromServer() {
  const params: any = {
    type: 'search-qrcode',
    idParam: route.params.id
  }
  onlineResult
    .FindQrcode(params)
    .then((v) => {
      if (v.data.code === 200) {
        idResponseQuery.value = v.data.data
        // console.log(idResponseQuery.value)
        setTimeout(() => {
          getListResponse()
        }, 1000)
        // getPin()
      }
      // ElMessage.success(`获取成功 ${v.num}`)
    })
    .catch((err) => {
      // ElMessage.error(err.message)
      console.log(err)
    })
}

const getDate = computed(() => (date: string) => {
  const dt = new Date(date)
  const timeFormate = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
  const resulTime = timeFormate.format(dt)

  const dateFormate = `${dt.getDate()}-${dt.getMonth() + 1}-${dt.getFullYear()}`

  return `${dateFormate} ${resulTime}`
})

const getSex = computed(() => (sex: string) => {
  switch (sex) {
    case 'M':
      return 'MASCULINO'
    case 'F':
      return 'FEMENINO'
    default:
      return 'OTRO'
  }
})

const typeStudy = computed(() => (modality: string) => {
  switch (modality) {
    case 'DX':
      return 'RADIOGRAFIA'
    case 'CR':
      return 'RADIOGRAFIA'
    case 'DR':
      return 'RADIOGRAFIA'
    case 'US':
      return 'SONOGRAFIA'
    case 'CT':
      return 'TOMOGRAFIA'
    case 'MG':
      return 'MAMOGRAFIA'
    case 'MR':
      return 'RESONANCIA'
    case 'SR':
      return 'RESONANCIA'
    default:
      return 'OTRO'
  }
})

function getListResponse() {
  const params: any = {
    type: 'getlist-response-query',
    idParam: idResponseQuery.value
  }
  onlineResult
    .FindQrcode(params)
    .then((v) => {
      if (v.data.code === 200) {
        const datajson = JSON.parse(v.data.data)
        datajson.forEach((element: any) => {
          const itemjson = JSON.parse(element.result)
          dataResponse.value.push(itemjson)
        })

        // console.log(dataResponse.value)

        if (dataResponse.value.length > 0) {
          hasResults.value = true
          idImage.value = `https://drive.google.com/thumbnail?id=${dataResponse.value[0].imgMedicalCenter}`
          codePin.value = dataResponse.value[0].codePin
          blockPage.value = true

          setTimeout(() => {
            getPin()
          }, 1000)
        }
        else {
          hasResults.value = false
          msgError.value = 'No se encontraron resultados.'
          idImage.value = ''
        }

        // getPin()
      }
      // ElMessage.success(`获取成功 ${v.num}`)
    })
    .catch((err) => {
      // ElMessage.error(err.message)
      console.log(err)
    })
}

function getPin() {
  Swal.fire({
    title: 'Digite su Pin',
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off'
    },
    showCancelButton: false,
    confirmButtonText: 'Ingresar',
    showLoaderOnConfirm: true,
    isDismissed: false,
    allowOutsideClick: false,
    allowEscapeKey: false

    // preConfirm: async (login:any) => {

    // },
    // allowOutsideClick: () => !Swal.isLoading()
  }).then((result: any) => {
    if (result.isConfirmed) {
      // console.log(result.value)

      // console.log(codePin.value)

      if (result.value === codePin.value) {
        blockPage.value = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Pin Valido..',
          showConfirmButton: false,
          timer: 1500
        })
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Verifica tu Pin',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout(() => {
          getPin()
        }, 2000)
      }

      // Swal.fire({
      //   title: `${result.value.login}'s avatar`,
      //   imageUrl: result.value.avatar_url
      // })
    }
  })
}

function viewRpt(url: string) {
  console.log(url)

  // const {href} = this.resolve(routeObject)
  window.open(url, '_blank')
}
</script>

<template>
  <div class="">
    <div v-if="!blockPage">
      <div class="container">
        <div v-if="hasResults">
          <div class="row">
            <div class="text-center">
              <div>
                <img
                  :src="idImage"
                  alt="Logo Institution"
                  style="
                  width: 250px;
                  max-width: 250px;
                  height: 200px;
                  background: #dddddd;
                  font-family: sans-serif;
                  font-size: 15px;
                  line-height: 15px;
                  color: #555555;
                  margin: auto;
                  display: block;
                "
                  class="g-img mt-3"
                >
              </div>
              <h4 class="mb-4">
                {{ dataResponse[0].medicalCenter }}
              </h4>

              <div class=" text-start mb-4">
                <h6 class="fw-light fs-6">
                  <span class=" fw-bold ">Nombre : &nbsp; </span>  {{ dataResponse[0].name }} {{ dataResponse[0].lastName }}
                </h6>
                <h6 class="fw-light fs-6">
                  <span class=" fw-bold mr-4 "> Sexo / Edad : &nbsp; </span>   {{ getSex(dataResponse[0].sex) }}  | {{ dataResponse[0].age }} A
                </h6>
                <h6 class="fw-light fs-6">
                  <span class=" fw-bold mr-4 "> Documento : &nbsp; </span>   {{ dataResponse[0].document }}
                </h6>
              </div>
            </div>
            <div class="col-24">
              <div class="container">
                <div class="table-responsive w-1px">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          #
                        </th>

                        <th scope="col" />

                        <th scope="col">
                          Estudio
                        </th>
                        <th scope="col">
                          Descripcion
                        </th>

                        <th scope="col">
                          Fecha
                        </th>
                        <th scope="col">
                          Estado
                        </th>
                        <th scope="col">
                          Opciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in dataResponse[0].studies" :key="index"
                      >
                        <th>
                          {{ index + 1 }}
                        </th>

                        <th>
                          <img style="width: 55px; height: 45px; cursor: pointer;" :src="item.preview" alt="image-study">
                        </th>

                        <td>
                          {{ typeStudy(item.modality) }}
                        </td>
                        <td>
                          {{ item
                            .Description }}
                        </td>

                        <td>
                          {{ getDate(item
                            .dateStudy) }}
                        </td>
                        <td>
                          {{ item
                            .report }}
                        </td>
                        <td>
                          <button v-for="(itemRpt, index2) in item.rpts " :key="index2" class="btn btn-primary" @click="viewRpt(itemRpt.urlRpt)">
                            Ver Reporte
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-5">
          <h3 class="text-danger text-center">
            {{ msgError }}
          </h3>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="">
        <div class="row">
          <div class="col-24 text-center">
            <img class=" img-fluid" src="@/assets/images/blockpage.png">
          </div>
        </div>
        <h3 class="text-danger text-center">
          Contenido bloqueado
        </h3>
      </div>
    </div>
  </div>
  <Footer />
</template>
