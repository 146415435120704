import ajax from '../ajax'

function FindQrcode(params: any): ResponseData<any> {
  // const config = {
  //   method: 'post',
  //   url: '/api/v2/onlineResults',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${'6hrFDATxrG9w14QY9wwnmVhLE0Wg6LIvwOwUaxz761m1JfRp4rs8Mzozk5xhSkw0_MQz6bpcJnrFUDwp5lPPFC157dHxbkKlDiQ9XY3ZIP8zAGCsS8ruN2uKjIaIargX'}`
  //   }

  // }
  return ajax.post('/api/v2/onlineResults', params)
}

/**
 * 获取验证码 - 无固定返回值格式
 * @param phone 手机号
 */
function getCode2(phone: string): Promise<{ num: number }> {
  return ajax.get('random/code', {
    params: {
      phone
    }
  })
}

export default {
  FindQrcode,
  getCode2
}
