<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'

import onlineResult from '../apis/modules/onlineResult'
import Footer from '@/components/Footer.vue'

const route = useRoute()
const idDocument = ref('')
const timerInterval = ref()
const dataResult = ref([])
const hashResult = ref('')
const hashItems = ref(false)

onMounted(() => {
  // console.log('LOADING')
  // console.log(route)
  idDocument.value = route.params.id as string
  Swal.fire({
    title: `Consultando [  ${idDocument.value}  ]`,
    html: 'Espere..!',
    timer: 7000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading()

      // const timer = Swal.getPopup().querySelector('b')
      // timerInterval.value = setInterval(() => {
      //   timer.textContent = `${Swal.getTimerLeft()}`
      // }, 100)
    },
    willClose: () => {
      clearInterval(timerInterval.value)
    }
  }).then((result: any) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {

    }
  })
  setTimeout(() => {
    idDocument.value = route.params.id as string
    getDataFromServer()
  }, 1000)
})

function getDataFromServer() {
  const params: any = {
    type: 'check_RD_DOCUMENTS',
    idParam: route.params.id
  }
  onlineResult
    .FindQrcode(params)
    .then((resp) => {
      if (resp.data.code === 200)
        // console.log(resp.data)
        if (resp.data.data !== '') {
          const separeResult = resp.data.data.split('^')
          dataResult.value = separeResult
          console.log(separeResult)
          hashResult.value = '__ RESULTADO __'
          hashItems.value = true
        }
        else {
          hashItems.value = false
          hashResult.value = `NO EXISTE DOCUMENTO : \xA0[ ${idDocument.value} ]`
        }
      // idResponseQuery.value = v.data.data
      // // console.log(idResponseQuery.value)
      // setTimeout(() => {
      //   getListResponse()
      // }, 1000)
      // // getPin()
    }).finally(() => {

    })
  // ElMessage.success(`获取成功 ${v.num}`)
    // }).catch((err) => {
    //   // ElMessage.error(err.message)
    //   console.log(err)
    // });
}
</script>

<template>
  <div class="container">
    <h3 class="text-danger mt-4 mb-3 text-center">
      {{ hashResult }}
    </h3>

    <div v-if="hashItems">
      <h6>{{ 'NOMBRE : ' }}{{ dataResult[0] }}</h6>
      <h6>{{ 'CEDULA : ' }}{{ dataResult[1] }}</h6>
      <h6>{{ 'F-NAC : ' }}{{ dataResult[2] }}</h6>
      <h6>{{ 'SEXO : ' }}{{ dataResult[3] }}</h6>
      <h6>{{ 'LUGAR_NAC : ' }}{{ dataResult[4] }}</h6>
      <h6>{{ 'TELEFONO : ' }}{{ dataResult[5] }}</h6>
    </div>

   
  </div>
  <Footer />
</template>
